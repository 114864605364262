import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SquareCardList, SquareCard } from "../components/cards"
import cleancode from "../images/cleancode.png"
import tdd from "../images/tdd.png"
import cryptoAreaChart from "../images/cryptoAreaChart.png"
import twelvefactorapp from "../images/12factor.png"
import react from "../images/react.png"
import reactcomponents from "../images/reactcomponents.png"
import serverless from "../images/serverless.png"
import cryptotwitter from "../images/cryptotwitter.jpg"
import stocktoflow from "../images/stocktoflow.png"
import devops from "../images/devops.png"
import bitcoinLinks from "../images/bitcoinlinks.jpg"
import braves from "../images/braves.jpg"
import falcons from "../images/falcons.png"
import hawks from "../images/hawks.png"
import ugalax from "../images/ugalax.jpg"
import techradar from "../images/techradar.jpg"
import leanenterprise from "../images/leanenterprise.jpg"
import whitepaper from "../images/whitepaper.png"
import productivitytrap from "../images/productivitytrap.png"

export default function Interests() {
  return (
    <div>
      <Layout subTitle="interests">
        <SEO title="Interests" />
        <h2>software development</h2>
        <SquareCardList>
        <SquareCard 
            image={productivitytrap} 
            title="Productivity Trap" 
            link="http://jack-roberts.com/videos/ProductivityTrap.mp4"
          />
          <SquareCard 
            image={cleancode} 
            title="Uncle Bob" 
            link="https://cleancoders.com/"
          />
          <SquareCard 
            image={tdd} 
            title="TDD"
            link="https://hackernoon.com/introduction-to-test-driven-development-tdd-61a13bc92d92"
          />
          <SquareCard 
            image={react} 
            title="React" 
            link="https://reactjs.org"
          />
          <SquareCard
            link="https://github.com/brillout/awesome-react-components"
            title="React Components"
            image={reactcomponents}
          />
          <SquareCard 
            image={devops} 
            title="State of DevOps"
            link="https://services.google.com/fh/files/misc/state-of-devops-2019.pdf"
          />
          <SquareCard 
            image={twelvefactorapp} 
            title="The 12 Factor App" 
            link="https://12factor.net"
          />
          <SquareCard
            link="https://serverless.com"
            title="Serverless Framework"
            image={serverless}
          />
          <SquareCard
            link="https://www.thoughtworks.com/radar"
            title="Tech Radar"
            image={techradar}
          />
          <SquareCard
            link="https://medium.com/@iamted/why-should-technologists-care-about-belief-96f8ef6377d1"
            title="Lean Enterprise"
            image={leanenterprise}
          />
        </SquareCardList>
        <h2>bitcoin</h2>
        <SquareCardList>
        <SquareCard
            link="https://bitcoin.org/bitcoin.pdf"
            title="The White Paper"
            image={whitepaper}
          />
          <SquareCard
            link="https://coin360.com"
            title="Crypto Area Chart"
            image={cryptoAreaChart}
          />
          <SquareCard
            link="https://twitter.com/search?q=bitcoin"
            title="Bitcoin Twitter"
            image={cryptotwitter}
          />
          <SquareCard
            link="https://digitalik.net/btc/"
            title="Stock to Flow Model"
            image={stocktoflow}
          />
          <SquareCard
            link="https://www.lopp.net/bitcoin-information.html"
            title="Lopp's Links"
            image={bitcoinLinks}
          />
        </SquareCardList>
        <h2>sports</h2>
        <SquareCardList>
          <SquareCard
            link="https://www.mlb.com/braves"
            title="Braves"
            image={braves}
          />
          <SquareCard
            link="https://www.atlantafalcons.com/"
            title="Falcons"
            image={falcons}
          />
          <SquareCard
            link="https://www.nba.com/hawks/"
            title="Hawks"
            image={hawks}
          />
          <SquareCard
            link="https://ugalax.com/"
            title="UGA Lax"
            image={ugalax}
          />
        </SquareCardList>      </Layout>
    </div>
  )
}

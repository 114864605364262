import React from "react"
import "./cards.css"

function openExternalLink(url) {
  console.log(url)
  window.open(url, "_blank")
}

export  function SquareCardList(props) {
  return (
    <div className="cards-list">
      {props.children}
    </div>
  )
}

export  function SquareCard(props) {
  console.log(props.link)
  return(
    <div className="card">
        <div className="card_image"> 
            <a className="card_image" 
              onClick={e => openExternalLink(props.link)} ><img 
              src={props.image} 
              alt={props.title}
            /></a>
        </div>
      <div className="card_title title-black">
        <p>{props.title}</p>
      </div>
    </div>
)
}